<template>
  <div class="artist-submission">
    <!-- submission section-->
    <section class="submission-section">
      <p>Artist Submission Form</p>
      <p>Interested in doing a drop with Royal? Submit the form below and we’ll get back to you if you qualify for our beta program.<br><br>
        We’ll be onboarding more artists throughout 2022, and we'll let you know when we’re ready for you. Stay tuned!</p>
      <div class="auth-input">
        <label for="artist-name">Artist Name <span>*</span></label>
        <input type="text" id="artist-name" placeholder="">
        <label for="email-address">Email address <span>*</span></label>
        <input type="email" id="email-address" placeholder="">
        <label>Music genre <span>*</span></label>
        <v-autocomplete
            v-model="musicGenre"
            :items="musicGenre"
            label="Find an option"
            chips
            solo
        ></v-autocomplete>
        <label for="spotify">Spotify Artist Profile Link <span>*</span></label>
        <input type="text" id="spotify" placeholder="">
        <label>Spotify Monthly Listeners <span>*</span></label>
        <v-autocomplete
            v-model="spotifyMonthlyListeners"
            :items="spotifyMonthlyListeners"
            label="Find an option"
            chips
            solo
        ></v-autocomplete>
        <label>Are you an independent artist? <span>*</span></label>
        <v-autocomplete
            v-model="independentArtist"
            :items="independentArtist"
            label="Find an option"
            chips
            solo
        ></v-autocomplete>
        <label>What's the status of the song you want to drop? <span>*</span></label>
        <v-autocomplete
            v-model="statusOfSong"
            :items="statusOfSong"
            label="Find an option"
            chips
            solo
        ></v-autocomplete>
        <label>Do you own 100% of the streaming revenue rights to the song? <span>*</span></label>
        <v-autocomplete
            v-model="streamingRevenueRights"
            :items="streamingRevenueRights"
            label="Find an option"
            chips
            solo
        ></v-autocomplete>
        <label>Do you own 100% of your artwork and have the ability to create derivatives? <span>*</span></label>
        <v-autocomplete
            v-model="artworkAndDerivatives"
            :items="artworkAndDerivatives"
            label="Find an option"
            chips
            solo
        ></v-autocomplete>
        <label for="etc">Is there anything else we should know about you?</label>
        <textarea id="etc"></textarea>
        <button type="submit">Submit</button>
        <p>Never submit passwords through this form. Report malicious form</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ArtistSubmission',
  components: {},
  data() {
    return {
      musicGenre: ['AfroBeats', 'Blues', 'Classical', 'Country', 'Dance', 'Folk', 'Jazz'  ],
      spotifyMonthlyListeners: ['7.5M+', '5M-7,5M', '3.5M-5M', '1M-3.5M', 'Below 1M'],
      independentArtist: ['Yes', 'No'],
      statusOfSong: ['Music is available on major streaming platforms', 'Music is completed but unreleased', 'Music is incompleted but unreleased'],
      streamingRevenueRights: ['Yes', 'No', "I'm not sure"],
      artworkAndDerivatives: ['Yes', 'No', "I'm not sure"],
    }
  },
  methods: {
  }
}
</script>